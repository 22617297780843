import { ParceiroNegocioOutrasInformacoes } from './../../interfaces/parceiro-negocio-outras-informacoes';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContaAlteracaoVendedor } from 'app/interfaces/conta-alteracao-vendedor';
import { Endereco } from 'app/interfaces/endereco';
import { Entidade } from 'app/interfaces/enum/entidade.enum';
import { Produto } from 'app/interfaces/enum/produto.enum';
import { StatusRequisito } from 'app/interfaces/enum/status-requisito.enum';
import { FiltroAlteracaoVendedor } from 'app/interfaces/filtro-alteracao-vendedor';
import { FuncaoParceiro } from 'app/interfaces/funcao-parceiro';
import { ImportacaoBI } from 'app/interfaces/importacao-bi';
import { Pageable } from 'app/interfaces/Pageable/pageable';
import { ParceiroNegocio } from 'app/interfaces/parceiro-negocio';
import { ParceiroNegocioCriacaoOfertaDTO } from 'app/interfaces/parceiro-negocio-criacao-oferta.dto';
import { ParceiroNegocioFarolMetas } from 'app/interfaces/parceiro-negocio-farol-metas';
import { ParceiroNegocioFornecedor } from 'app/interfaces/parceiro-negocio-fornecedor';
import { ParceiroNegocioHeader } from 'app/interfaces/parceiro-negocio-header';
import { ParceiroNegocioNomeFuncaoDTO } from 'app/interfaces/parceiro-negocio-nome-funcao.dto';
import { ParceiroNegocioRemuneracao } from 'app/interfaces/parceiro-negocio-remuneracao';
import { ParceiroNegocioRequisito } from 'app/interfaces/parceiro-negocio-requisito';
import { ParceiroNegocioSeguradora } from 'app/interfaces/parceiro-negocio-seguradora';
import { ParceiroRequisitosAtualizacaoDataVencimento } from 'app/interfaces/parceiro-requisitos-atualizacao-data-vencimento';
import { ParceiroRequisitosAtualizacaoTaxa } from 'app/interfaces/parceiro-requisitos-atualizacao-taxa';
import { ParceiroTermoAnualDemurrage } from 'app/interfaces/parceiro-termo-anual-demurrage';
import { RequisitoAprovacao } from 'app/interfaces/requisito-aprovacao';
import { Sop } from 'app/interfaces/sop';
import { TimezoneService } from 'app/utils/timezone.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ParceiroHttpService {
  constructor(private _http: HttpClient, private _timezoneService: TimezoneService) { }

  /**
   * Função para buscar todas parceiro-negocios;
   * @param filtro
   * @param statusParceiroNegocio
   * @param nomeParceiroVendedor
   * @param page
   * @returns
   */
  getAll(filtro = {}, sort = '', page = 0) {
    const params = {
      ...filtro,
      sort: sort,
      page: page,
      
    };
    return this._http.get<Pageable>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios`, { params });
  }

    /**
     * Função para buscar todas contas;
     * @param filtro
     * @param statusParceiroNegocio
     * @param nomeParceiroVendedor
     * @param page
     * @returns
     */
    getAllContas(filtro = {}, sort = '', page = 0) {
      const params = {
        ...filtro,
        sort: sort,
        page: page,
        
      };
      return this._http.get<Pageable>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/conta`, { params });
    }

  /**
   * Função para buscar a parceiroNegocio pelo id;
   * @param id
   * @returns
   */
  getById(id: number) {
    return this._http.get<ParceiroNegocio>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/${id}`);
  }
  
    /**
   * Busca as funções do parceiro pelo id
   * @param id
   * @returns
   */
    getFuncaoParcerioById(id: number) {
      return this._http.get<ParceiroNegocio>(
        `${environment.apiUrl}/api/partner/v1/parceiro-negocios/get-funcao-parceiro-by-id`, 
        { params: { parceiroNegociosId: id.toString() } }
      );
    }
  /**
   * Busca parceiros de acordo com a permissão do usuário logado;
   * @param id
   * @returns
   */
  getByIdPermissaoVendedor(id: number, entidade: Entidade) {
    console.log(entidade);
    return this._http.get<ParceiroNegocio>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/detalhes/${id}?entidade=${entidade}`);
  }

  /**
   * Busca os parceiros usando a funcao e o nome (se o nome for nulo busca apenas pela funcao)
   * @param nome
   * @param funcaoParceiro
   * @returns
   */
  getByName(nome: string) {
    return this._http.get<ParceiroNegocio[]>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/find-by-name?nome=${nome}`);
  }

  /**
   * Busca os parceiros usando a funcao e o nome (se o nome for nulo busca apenas pela funcao)
   * @param nome
   * @param funcaoParceiro
   * @returns
   */
  getAtivosByName(nome: string) {
    return this._http.get<ParceiroNegocio[]>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/find-ativos-by-name?nome=${nome}`);
  }

  /**
   * Busca os parceiros usando a funcao e o nome (se o nome for nulo busca apenas pela funcao)
   * @param nome
   * @param funcaoParceiro
   * @returns
   */
  getAtivosByParceiros(parceiroNegocioId: number[]) {
    return this._http.get<ParceiroNegocio[]>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios/find-ativos-by-ids?ids=${parceiroNegocioId}`
    );
  }
  /**
   * Busca os parceiros usando a funcao e o nome (se o nome for nulo busca apenas pela funcao)
   * @param nome
   * @returns
   */
  getAtivosWithFuncaoByName(nome: string) {
    return this._http.get<ParceiroNegocio[]>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/find-ativos-with-funcao-by-name?nome=${nome}`);
  }

  /**
   * Busca id da Função Parceiro pelo nome.
   * @param descricao
   * @returns
   */
  getFuncaoParceiroByDescricao(descricao: string) {
    return this._http.get<FuncaoParceiro[]>(`${environment.apiUrl}/api/partner/v1/funcao-parceiros/find-by-descricao?descricao=${descricao}`);
  }

  /**
   * Busca os parceiros usando a funcao e o nome (se o nome for nulo busca apenas pela funcao)
   * @param nome
   * @param funcaoParceiro
   * @returns
   */
  getParceiroByNomeFuncao(nome = '', funcaoParceiro: string, produto = '') {
    return this._http.get<ParceiroNegocioNomeFuncaoDTO[]>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios/funcao?nome=${nome}&funcaoParceiro=${funcaoParceiro}&produto=${produto}`
    );
  }

  /**
   * Busca os parceiros usando a funcao e o nome (se o nome for nulo busca apenas pela funcao)
   * @param nome
   * @param funcaoParceiro
   * @returns
   */
  getParceiroByNomeFuncaoUsuarioAtivo(nome = '', funcaoParceiro: string, produto = '') {
    return this._http.get<ParceiroNegocioNomeFuncaoDTO[]>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios/funcao-usuario-ativo?nome=${nome}&funcaoParceiro=${funcaoParceiro}&produto=${produto}`
    );
  }

  /**
   * Busca os parceiros usando a funcao e o nome (se o nome for nulo busca apenas pela funcao)
   * @param nome
   * @param funcaoParceiro
   * @returns
   */
  getParceiroByNomeFuncoes(nome = '', funcaoParceiro: string[], produto = '', paisId?: number) {
    return this._http.get<ParceiroNegocio[]>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios/funcoes?nome=${nome}&funcoesParceiro=${funcaoParceiro}&produto=${produto}&paisId=${paisId}`
    );
  }

  /**
   * Busca os parceiros usando a funcao e o nome (se o nome for nulo busca apenas pela funcao)
   * @param nome
   * @param funcaoParceiro
   * @returns
   */
  getParceiroByNomeFuncaoAndPais(nome = '', funcaoParceiro: string, paisesId: number[]) {
    return this._http.get<ParceiroNegocio[]>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios/funcao-and-paises?nome=${nome}&funcao=${funcaoParceiro}&paisesIds=${paisesId.join(',')}`
    );
  }

  /**
   * Busca os parceiros usando mais de uma funcao e o nome (se o nome for nulo busca apenas pela funcao)
   * @param nome
   * @param funcaoParceiro
   * @returns
   */
  getParceiroFornecedor(nome = '') {
    return this._http.get<ParceiroNegocioFornecedor[]>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/find-fornecedor?nome=${nome}`);
  }

  /**
   * Busca o InsideSales padrão daquele vendedor através do id do vendedor, caso não possuir retorna nulo.
   * @param vendedorId
   * @returns
   */
  getISPadraoByVendedorId(vendedorId: number) {
    return this._http.get<ParceiroNegocio>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/inside-sales-padrao-by-vendedor-id/${vendedorId}`);
  }

  /**
   * Busca os parceiros da familia.
   * @param familiaId
   * @returns
   */
  getParceirosByFamilia(familiaId: number) {
    return this._http.get<ParceiroNegocio[]>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/busca-parceiros-by-familia-id/${familiaId}`);
  }

  /**
   * Busca o Vendedor padrão daquele cliente através do id do cliente, caso não possuir retorna nulo.
   * @param parceiroClienteId
   * @returns
   */
  getVendedorPadraoByClienteId(parceiroClienteId: number) {
    return this._http.get<ParceiroNegocio>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios/vendedor-padrao-by-cliente-id/${parceiroClienteId}`
    );
  }

  /**
   *
   * @param id
   * @returns
   */
  getInstrucoesById(id: number) {
    return this._http.get<ParceiroNegocio>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/instrucoes/${id}`);
  }

  /**
   *
   * @param id
   * @returns
   */
  getEnderecosById(parceiroNegocioId: number) {
    return this._http.get<Endereco>(`${environment.apiUrl}/api/partner/v1/enderecos?parceiroNegocioId=${parceiroNegocioId}`);
  }

  /**
   * Função para salvar uma parceiroNegocio;
   * @param parceiroNegocio
   * @returns
   */
  salvarParceiro(parceiroNegocio: ParceiroNegocio) {
    return this._http.post<ParceiroNegocio>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios`, parceiroNegocio);
  }

  /**
   * Função para salvar uma parceiroNegocio;
   * @param parceiroNegocio
   * @returns
   */
  salvarConta(parceiroNegocio: ParceiroNegocio) {
    return this._http.post<ParceiroNegocio>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/conta`, parceiroNegocio);
  }

  /**
   * Função para atualizar uma parceiroNegocio;
   * @param parceiroNegocio
   * @returns
   */
  update(parceiroNegocio: ParceiroNegocio) {
    return this._http.put<ParceiroNegocio>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios/${parceiroNegocio.parceiroNegocioId}`,
      parceiroNegocio
    );
  }

  /**
   * Função para atualizar os responsáveis de um parceiroNegocio;
   * @param parceiroNegocio
   * @returns
   */
  updateResponsaveis(parceiroNegocio: ParceiroNegocio) {
    return this._http.put<ParceiroNegocio>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios/responsaveis/${parceiroNegocio.parceiroNegocioId}`,
      parceiroNegocio
    );
  }

  /**
   * Função para atualizar o vendedor de um ou mais parceiroNegocio;
   * @param familiaId
   * @param parceiroNegocioVendedorId
   * @returns
   */
  updateVendedoresFamiliaParceiro(familiaId: Number, parceiroNegocioVendedor: ParceiroNegocio) {
    return this._http.put<boolean>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios/vendedor-familia-conta?familiaId=${familiaId}`,
      parceiroNegocioVendedor
    );
  }

  /**
   * Função para atualizar as instrucoes de uma parceiroNegocio;
   * @param parceiroNegocio
   * @returns
   */
  updateInstrucoes(parceiroNegocio: ParceiroNegocio) {
    return this._http.put<ParceiroNegocio>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios/instrucoes/${parceiroNegocio.parceiroNegocioId}`,
      parceiroNegocio
    );
  }

  /**
   * Função para atualizar os enderecos de uma parceiroNegocio;
   * @param parceiroNegocio
   * @returns
   */
  updateEnderecos(parceiroNegocioId: number, endereco: Endereco) {
    return this._http.put<Boolean>(
      `${environment.apiUrl}/api/partner/v1/enderecos/${endereco.enderecoId}?parceiroNegocioId=${parceiroNegocioId}`,
      endereco
    );
  }

  /**
   *
   * @param parceiroNegocioId
   * @param endereco
   * @returns
   */
  salvaNovoEndereco(parceiroNegocioId: number, endereco: Endereco) {
    return this._http.post<any>(`${environment.apiUrl}/api/partner/v1/enderecos?parceiroNegocioId=${parceiroNegocioId}`, endereco);
  }

  /**
   * Delete um registro;
   * @param id
   * @returns
   */
  delete(id: number) {
    return this._http.delete<boolean>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/${id}`);
  }

  /**
   *
   * @param id
   * @param ativo
   * @returns
   */
  atualizaParceiroNegocioAtivo(id: number, parceiro: ParceiroNegocioHeader) {
    return this._http.put<Boolean>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/ativo/${id}`, parceiro);
  }

  /**
   * Busca os requisitos daquela operação e parceiro de negócio;
   * @param param0
   * @returns
   */
  getParceiroNegocioRequisitoById(parceiroNegocioRequisitoId: number) {
    return this._http.get<ParceiroNegocioRequisito>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocio-requisitos/nao-aprovados/${parceiroNegocioRequisitoId}`
    );
  }

  /**
   * Grava todos os requisitos preenchidos na tela;
   * @param parceiroNegocioId
   * @param tipoOperacao
   * @param requisitos
   * @returns
   */
  salvaParceiroNegocioRequisito(parceiroNegocioId: number, parceiroNegocioRequisito: ParceiroNegocioRequisito) {
    return this._http.post<ParceiroNegocioRequisito>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocio-requisitos?parceiroNegocioId=${parceiroNegocioId}`,
      parceiroNegocioRequisito
    );
  }

  /**
   *
   * @param parceiroNegocioRequisitoId
   * @param requisitos
   * @returns
   */
  atualizaRequisitos(parceiroNegocioRequisitoId: number, parceiroNegocioRequisito: ParceiroNegocioRequisito) {
    return this._http.put<ParceiroNegocioRequisito>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocio-requisitos/${parceiroNegocioRequisitoId}`,
      parceiroNegocioRequisito
    );
  }

  deleteParceiroNegocioRequisito(parceiroNegocioRequisitoId: number) {
    return this._http.delete<Boolean>(`${environment.apiUrl}/api/partner/v1/parceiro-negocio-requisitos/${parceiroNegocioRequisitoId}`);
  }

  /**
   * Busca os parceiros usando a funcao e o nome (se o nome for nulo busca apenas pela funcao)
   * @param nome
   * @param funcaoParceiro
   * @returns
   */
  getAgentesClassificadosByNomeOrigemDestino(nome = '', produto = '', origemId = null, destinoId = null) {
    const params = {
      nome: nome,
      produto: produto,
      origemId: origemId ? origemId : '',
      destinoId: destinoId ? destinoId : '',
    };
    return this._http.get<ParceiroNegocio[]>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/agente-classificado-origem-destino`, { params });
  }

  /**
   *
   * @param requisitoId
   * @param requisitoAprovacaoId
   * @param status
   * @returns
   */
  aprovaRequisito(requisitoAprovacaoId: number, status: StatusRequisito) {
    let params = {
      requisitoAprovacaoId: requisitoAprovacaoId,
      status: status,
    };

    return this._http.put<RequisitoAprovacao>(`${environment.apiUrl}/api/partner/v1/requisitos-aprovacoes/atualiza-status-aprovador`, null, {
      params: params,
    });
  }

  getParceiroNegocioRequisito(parceiroNegocioId: number) {
    return this._http.get<ParceiroNegocioRequisito[]>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocio-requisitos?parceiroNegocioId=${parceiroNegocioId}`
    );
  }

  getParceiroNegocioRequisitoByProduto(parceiroNegocioId: number, produto: Produto) {
    const params = {
      parceiroNegocioId: parceiroNegocioId,
      produto: produto,
    };
    return this._http.get<ParceiroNegocioRequisito>(`${environment.apiUrl}/api/partner/v1/parceiro-negocio-requisitos/by-produto-e-parceiro`, {
      params,
    });
  }

  /**
   * Busca os dados de TEUs do parceiro de negócio
   * @param param0
   * @returns
   */
  getImportacaoBI({ parceiroNegocioId }: { parceiroNegocioId: number }) {
    return this._http.get<ImportacaoBI[]>(
      `${environment.apiUrl}/api/partner/v1/importacao-bi/search/parceiro?parceiroNegocioId=${parceiroNegocioId}`
    );
  }

  /**
   * Busca os dados de TEUs da familia
   * @param param0
   * @returns
   */
  getImportacaoBIByFamiliaId({ parceiroNegocioId }: { parceiroNegocioId: number }) {
    return this._http.get<ImportacaoBI[]>(
      `${environment.apiUrl}/api/partner/v1/importacao-bi/search/parceiro?parceiroNegocioId=${parceiroNegocioId}`
    );
  }

  getImportacaoBIMaritima(parceiroNegocioId: number, produto: Produto) {
    return this._http.get<ImportacaoBI[]>(
      `${environment.apiUrl}/api/partner/v1/importacao-bi/importacao-maritima?parceiroNegocioId=${parceiroNegocioId}&produto=${produto}`
    );
  }

  /**
   *
   * @param parceiroNegocioId
   * @param outrasInformacoes
   * @returns
   */
  updateOutrasInformacoes(parceiroNegocioId: number, outrasInformacoes: ParceiroNegocioOutrasInformacoes) {
    return this._http.put<ParceiroNegocioOutrasInformacoes>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios/outras-informacoes/${parceiroNegocioId}`,
      outrasInformacoes
    );
  }

  getOutrasInformacoes(parceiroNegocioId: number) {
    return this._http.get<ParceiroNegocioOutrasInformacoes>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios/outras-informacoes/${parceiroNegocioId}`
    );
  }

  
  getRemuneracaoInformacoes(parceiroNegocioId: number) {
    return this._http.get<ParceiroNegocio>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios/remuneracao/${parceiroNegocioId}`
    );
  }

  updateRemuneracao(parceiroNegocioId: number, remuneracao: ParceiroNegocioRemuneracao) {
    return this._http.put<ParceiroNegocioOutrasInformacoes>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios/remuneracao/${parceiroNegocioId}`,
      remuneracao
    );
  }
  /**
   * Função para validar se o cliente também possui a função agente;
   * @param id
   * @returns
   */
  verificarSeClienteIsAgente(clienteId: number) {
    return this._http.get<boolean>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/cliente-is-agente/${clienteId}`);
  }

  /**
   * Atualiza informações do header da conta, hoje atualiza apenas o status;
   * @param parceiroNegocioId
   * @param parceiroNegocio
   * @returns
   */
  atualizaInformacoesHeader(parceiroNegocioId: number, parceiroNegocio: ParceiroNegocioHeader) {
    return this._http.put<ParceiroNegocio>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios/infos-header/${parceiroNegocioId}`,
      parceiroNegocio
    );
  }

  /**
   * Função para validar se o vendedor pode ou não ver os detalhes do parceiro;
   * @param id
   * @returns
   */
  validaDetalhesPermissao(id: number) {
    return this._http.get<Boolean>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/permissao/${id}`);
  }

  /**
   * Método para trocar o status da conta para PROSPECT;
   * @param parceiroNegocioId
   * @returns
   */
  trocaSuspectParaProspect(parceiroNegocioId: number) {
    return this._http.put<ParceiroNegocio>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/troca-status-prospect/${parceiroNegocioId}`, null);
  }

  /**
   * Método para trocar o status da conta para LEAD;
   * @param parceiroNegocioId
   * @returns
   */
  trocaProspectParaLead(parceiroNegocioId: number) {
    return this._http.put<ParceiroNegocio>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/troca-status-lead/${parceiroNegocioId}`, null);
  }

  /**
   * Método para trocar o status da conta para CLIENTE ATIVO;
   * @param parceiroNegocioId
   * @returns
   */
  trocaStatusClienteAtivo(parceiroNegocioId: number) {
    return this._http.put<ParceiroNegocio>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios/troca-status-cliente-ativo/${parceiroNegocioId}`,
      null
    );
  }

  /**
   * Função para buscar a parceiroNegocio pelo id;
   * @param id
   * @returns
   */
  getClassificacaobyParceiroId(id: number) {
    return this._http.get<ParceiroNegocio>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/classificacao/${id}`);
  }

  getParceiroNegocioRepresentanteToProcesso(produto: Produto, origemDestino: number) {
    const params = {produto, origemDestino}
    return this._http.get<ParceiroNegocio>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/representantes`, {params});
  }

  /**
   * Função para buscar a quantidade de clientes do vendedor pelo id do vendeodr;
   * @param id
   * @returns
   */
  getQtdClientsbySalesId() {
    return this._http.get<ParceiroNegocio>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/find-qtd-clients-for-sales`);
  }

  /**
   * Função para buscar o SOP através do ID do parceiro;
   * @param id
   * @returns
   */
  getSopByParceiroId(parceiroId: number) {
    const params = {
      parceiroId: parceiroId,
    };
    return this._http.get<Sop>(`${environment.apiUrl}/api/partner/v1/sop`, { params });
  }

  /**
   * Função para salvar novo SOP através do ID do parceiro;
   * @param id
   * @returns
   */
  saveSopByParceiroId(parceiroId: number, sop: Sop) {
    return this._http.post<Sop>(`${environment.apiUrl}/api/partner/v1/sop?parceiroId=${parceiroId}`, sop);
  }

  updateSop(sopId: number, sop: Sop) {
    return this._http.put<Sop>(`${environment.apiUrl}/api/partner/v1/sop/${sopId}`, sop);
  }

  getSopByParceiroNegocioId(parceiroId: number) {
    return this._http.get<Sop>(`${environment.apiUrl}/api/partner/v1/sop/SopByParceiro/${parceiroId}`);
  }

  /**
   * Função para atualizar SOP através do ID do parceiro;
   * @param id
   * @returns
   */
  updateSopByParceiroId(parceiroId: number, sop: Sop) {
    return this._http.put<Sop>(`${environment.apiUrl}/api/partner/v1/sop?parceiroId=${parceiroId}`, sop);
  }

  getIsParceiroAgenteExteriorById(parceiroNegocioId: number) {
    return this._http.get<boolean>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/cliente-is-agente-exterior/${parceiroNegocioId}`);
  }

  /**
   * Função para buscar o último farol de metas cadastrado para o parceiro de negócio (vendedor);
   * @param parceiroNegocioId
   * @returns
   */
  getLastFarolMetasByParceiroNegocioId(parceiroNegocioId: number) {
    return this._http.get<ParceiroNegocioFarolMetas>(`${environment.apiUrl}/api/partner/v1/parceiro-negocio-farol-metas/last/${parceiroNegocioId}`);
  }

  /**
   * Função para buscar farol de metas;
   * @param parceiroNegocioId
   * @returns
   */
  getAllFarolMetas(filtro = {}, page = 0, sort = '', farol = '') {
    const params = {
      ...filtro,
      farol: farol,
      sort: sort,
      page: page,
      size: 100,
    };

    return this._http.get<Pageable>(`${environment.apiUrl}/api/partner/v1/parceiro-negocio-farol-metas`, { params });
  }

  /**
   * Função para buscar infos referente a spread;
   * @returns
   */
  validarInfosAtualizacaoTaxa(parceiroNegocioId: number, produto: Produto, empresaId: number) {
    return this._http.get<ParceiroRequisitosAtualizacaoTaxa>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios/validar-infos-atualizacao-taxa?parceiroNegocioId=${parceiroNegocioId}&produto=${produto}&empresaId=${empresaId}`
    );
  }

  /**
   * Função para buscar infos referente a spread;
   * @returns
   */
  validarInfosAtualizacaoDataVencimento(parceiroNegocioId: number, produto: Produto, empresaId: number) {
    return this._http.get<ParceiroRequisitosAtualizacaoDataVencimento>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios//validar-infos-atualizacao-data-vencimento?parceiroNegocioId=${parceiroNegocioId}&produto=${produto}&empresaId=${empresaId}`
    );
  }

  validaVendedorAtivoCliente(parceiroNegocioId: number) {
    return this._http.get<ParceiroRequisitosAtualizacaoDataVencimento>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios/vendedor/ativo/${parceiroNegocioId}`
    );
  }

  verificaParceiroSMX(parceiroNegocioId: number, cnpj: string) {
    const params = {
      cnpj: cnpj,
    };

    return this._http.get<Boolean>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/verifica-parceiro-smx/${parceiroNegocioId}`, { params });
  }

  getInfosSeguradora(parceiroNegocioId: number) {
    return this._http.get<ParceiroNegocioSeguradora>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios/outras-informacoes/seguradora/${parceiroNegocioId}`
    );
  }

  findResumoParceiroById(parceiroNegocioId: number) {
    return this._http.get<ParceiroNegocio>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/find-resumo-parceiro-by-id/${parceiroNegocioId}`);
  }


  findAllContaParaAlteracaoVendedor(page = 0, sort = '', filtro: FiltroAlteracaoVendedor) {
    const params = { page, sort }
    return this._http.post<Pageable>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/get-contas-alteracao-vendedor`, filtro, { params });
  }

  updateContasAlteracaoVendedor(body: ContaAlteracaoVendedor) {
    return this._http.put<boolean>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/update-contas-alteracao-vendedor`, body);
  }

  getAllAuditParceiroNegocio(parceiroNegocioId: number, page: number, email: string, dataInicial: Date, dataFinal: Date, tipoAuditoria: string) {
    let params = {
      size: 10,
      page: page,
      dataInicial: dataInicial ? this._timezoneService.utcToUserString(dataInicial) : '',
      dataFinal: dataFinal ? this._timezoneService.utcToUserString(dataFinal) : '',
      email: email ? email : '',
      tipoAuditoria: tipoAuditoria ? tipoAuditoria : '',
    };

    return this._http.get<Pageable>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios-auditorias/${parceiroNegocioId}`, {
      params: params,
    });
  }

  getAllAuditParceiroNegocioAgente(
    parceiroNegocioAgenteId: number,
    page: number,
    email: string,
    dataInicial: Date,
    dataFinal: Date,
    tipoAuditoria: string
  ) {
    let params = {
      size: 10,
      page: page,
      dataInicial: dataInicial ? this._timezoneService.utcToUserString(dataInicial) : '',
      dataFinal: dataFinal ? this._timezoneService.utcToUserString(dataFinal) : '',
      email: email ? email : '',
      tipoAuditoria: tipoAuditoria ? tipoAuditoria : '',
    };

    return this._http.get<Pageable>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios-agentes-auditorias/${parceiroNegocioAgenteId}`, {
      params: params,
    });
  }

  getAllAuditParceiroNegocioArmador(
    parceiroNegocioArmadorId: number,
    page: number,
    email: string,
    dataInicial: Date,
    dataFinal: Date,
    tipoAuditoria: string
  ) {
    let params = {
      size: 10,
      page: page,
      dataInicial: dataInicial ? this._timezoneService.utcToUserString(dataInicial) : '',
      dataFinal: dataFinal ? this._timezoneService.utcToUserString(dataFinal) : '',
      email: email ? email : '',
      tipoAuditoria: tipoAuditoria ? tipoAuditoria : '',
    };

    return this._http.get<Pageable>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios-armadores-auditorias/${parceiroNegocioArmadorId}`, {
      params: params,
    });
  }
  getAllAuditParceiroNegocioCompanhiaAerea(
    parceiroNegocioCompanhiaAereaId: number,
    page: number,
    email: string,
    dataInicial: Date,
    dataFinal: Date,
    tipoAuditoria: string
  ) {
    let params = {
      size: 10,
      page: page,
      dataInicial: dataInicial ? this._timezoneService.utcToUserString(dataInicial) : '',
      dataFinal: dataFinal ? this._timezoneService.utcToUserString(dataFinal) : '',
      email: email ? email : '',
      tipoAuditoria: tipoAuditoria ? tipoAuditoria : '',
    };

    return this._http.get<Pageable>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios-companhias-aereas-auditorias/${parceiroNegocioCompanhiaAereaId}`,
      {
        params: params,
      }
    );
  }
  getAllAuditParceiroNegocioColaborador(
    parceiroNegocioColaboradorId: number,
    page: number,
    email: string,
    dataInicial: Date,
    dataFinal: Date,
    tipoAuditoria: string
  ) {
    let params = {
      size: 10,
      page: page,
      dataInicial: dataInicial ? this._timezoneService.utcToUserString(dataInicial) : '',
      dataFinal: dataFinal ? this._timezoneService.utcToUserString(dataFinal) : '',
      email: email ? email : '',
      tipoAuditoria: tipoAuditoria ? tipoAuditoria : '',
    };

    return this._http.get<Pageable>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios-colaboradores-auditorias/${parceiroNegocioColaboradorId}`,
      {
        params: params,
      }
    );
  }

  getAllAuditParceiroNegocioCourier(
    parceiroNegocioCourierId: number,
    page: number,
    email: string,
    dataInicial: Date,
    dataFinal: Date,
    tipoAuditoria: string
  ) {
    let params = {
      size: 10,
      page: page,
      dataInicial: dataInicial ? this._timezoneService.utcToUserString(dataInicial) : '',
      dataFinal: dataFinal ? this._timezoneService.utcToUserString(dataFinal) : '',
      email: email ? email : '',
      tipoAuditoria: tipoAuditoria ? tipoAuditoria : '',
    };

    return this._http.get<Pageable>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios-couriers-auditorias/${parceiroNegocioCourierId}`, {
      params: params,
    });
  }

  getAllAuditParceiroNegocioRepresentante(
    parceiroNegocioRepresentanteId: number,
    page: number,
    email: string,
    dataInicial: Date,
    dataFinal: Date,
    tipoAuditoria: string
  ) {
    let params = {
      size: 10,
      page: page,
      dataInicial: dataInicial ? this._timezoneService.utcToUserString(dataInicial) : '',
      dataFinal: dataFinal ? this._timezoneService.utcToUserString(dataFinal) : '',
      email: email ? email : '',
      tipoAuditoria: tipoAuditoria ? tipoAuditoria : '',
    };

    return this._http.get<Pageable>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios-representantes-auditorias/${parceiroNegocioRepresentanteId}`,
      {
        params: params,
      }
    );
  }

  getAllAuditParceiroNegocioSeguradora(
    parceiroNegocioSeguradoraId: number,
    page: number,
    email: string,
    dataInicial: Date,
    dataFinal: Date,
    tipoAuditoria: string
  ) {
    let params = {
      size: 10,
      page: page,
      dataInicial: dataInicial ? this._timezoneService.utcToUserString(dataInicial) : '',
      dataFinal: dataFinal ? this._timezoneService.utcToUserString(dataFinal) : '',
      email: email ? email : '',
      tipoAuditoria: tipoAuditoria ? tipoAuditoria : '',
    };

    return this._http.get<Pageable>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios-seguradoras-auditorias/${parceiroNegocioSeguradoraId}`, {
      params: params,
    });
  }

  getAllAuditParceiroNegocioTerminal(
    parceiroNegocioTerminalId: number,
    page: number,
    email: string,
    dataInicial: Date,
    dataFinal: Date,
    tipoAuditoria: string
  ) {
    let params = {
      size: 10,
      page: page,
      dataInicial: dataInicial ? this._timezoneService.utcToUserString(dataInicial) : '',
      dataFinal: dataFinal ? this._timezoneService.utcToUserString(dataFinal) : '',
      email: email ? email : '',
      tipoAuditoria: tipoAuditoria ? tipoAuditoria : '',
    };

    return this._http.get<Pageable>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios-terminais-auditorias/${parceiroNegocioTerminalId}`, {
      params: params,
    });
  }

  getAllAuditParceiroNegocioTransportadora(
    parceiroNegocioTransportadoraId: number,
    page: number,
    email: string,
    dataInicial: Date,
    dataFinal: Date,
    tipoAuditoria: string
  ) {
    let params = {
      size: 10,
      page: page,
      dataInicial: dataInicial ? this._timezoneService.utcToUserString(dataInicial) : '',
      dataFinal: dataFinal ? this._timezoneService.utcToUserString(dataFinal) : '',
      email: email ? email : '',
      tipoAuditoria: tipoAuditoria ? tipoAuditoria : '',
    };

    return this._http.get<Pageable>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios-transportadoras-auditorias/${parceiroNegocioTransportadoraId}`,
      {
        params: params,
      }
    );
  }

  getAllAuditParceiroNegocioVendedor(
    parceiroNegocioVendedorId: number,
    page: number,
    email: string,
    dataInicial: Date,
    dataFinal: Date,
    tipoAuditoria: string
  ) {
    let params = {
      size: 10,
      page: page,
      dataInicial: dataInicial ? this._timezoneService.utcToUserString(dataInicial) : '',
      dataFinal: dataFinal ? this._timezoneService.utcToUserString(dataFinal) : '',
      email: email ? email : '',
      tipoAuditoria: tipoAuditoria ? tipoAuditoria : '',
    };

    return this._http.get<Pageable>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios-vendedores-auditorias/${parceiroNegocioVendedorId}`, {
      params: params,
    });
  }
  getAllAuditContatoCRMeParceiroNegocio(
    parceiroNegocioId: number,
    page: number,
    email: string,
    dataInicial: Date,
    dataFinal: Date,
    tipoAuditoria: string
  ) {
    let params = {
      size: 10,
      page: page,
      dataInicial: dataInicial ? this._timezoneService.utcToUserString(dataInicial) : '',
      dataFinal: dataFinal ? this._timezoneService.utcToUserString(dataFinal) : '',
      email: email ? email : '',
      tipoAuditoria: tipoAuditoria ? tipoAuditoria : '',
    };

    return this._http.get<Pageable>(`${environment.apiUrl}/api/partner/v1/contatos-auditorias/${parceiroNegocioId}`, {
      params: params,
    });
  }

  getAuditComparacaoParceiroNegocio(parceiroNegocioId: number, rev: number) {
    return this._http.get<any[]>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios-auditorias/comparacao-audit/${parceiroNegocioId}?rev=${rev}`
    );
  }

  getAuditComparacaoParceiroNegocioAgente(parceiroNegocioAgenteId: number, rev: number) {
    return this._http.get<any[]>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios-agentes-auditorias/comparacao-audit/${parceiroNegocioAgenteId}?rev=${rev}`
    );
  }
  getAuditComparacaoParceiroNegocioArmador(parceiroNegocioArmadorId: number, rev: number) {
    return this._http.get<any[]>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios-armadores-auditorias/comparacao-audit/${parceiroNegocioArmadorId}?rev=${rev}`
    );
  }

  getAuditComparacaoParceiroNegocioCompanhiaAerea(parceiroNegocioCompanhiaAereaId: number, rev: number) {
    return this._http.get<any[]>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios-companhias-aereas-auditorias/comparacao-audit/${parceiroNegocioCompanhiaAereaId}?rev=${rev}`
    );
  }
  getAuditComparacaoParceiroNegocioColaborador(parceiroNegocioColaboradorId: number, rev: number) {
    return this._http.get<any[]>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios-colaboradores-auditorias/comparacao-audit/${parceiroNegocioColaboradorId}?rev=${rev}`
    );
  }
  getAuditComparacaoParceiroNegocioCourier(parceiroNegocioCourierId: number, rev: number) {
    return this._http.get<any[]>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios-couriers-auditorias/comparacao-audit/${parceiroNegocioCourierId}?rev=${rev}`
    );
  }
  getAuditComparacaoParceiroNegocioRepresentante(parceiroNegocioRepresentanteId: number, rev: number) {
    return this._http.get<any[]>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios-representantes-auditorias/comparacao-audit/${parceiroNegocioRepresentanteId}?rev=${rev}`
    );
  }
  getAuditComparacaoParceiroNegocioSeguradora(parceiroNegocioSeguradoraId: number, rev: number) {
    return this._http.get<any[]>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios-seguradoras-auditorias/comparacao-audit/${parceiroNegocioSeguradoraId}?rev=${rev}`
    );
  }
  getAuditComparacaoParceiroNegocioTerminal(parceiroNegocioTerminalId: number, rev: number) {
    return this._http.get<any[]>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios-terminais-auditorias/comparacao-audit/${parceiroNegocioTerminalId}?rev=${rev}`
    );
  }
  getAuditComparacaoParceiroNegocioTransportadora(parceiroNegocioTransportadoraId: number, rev: number) {
    return this._http.get<any[]>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios-transportadoras-auditorias/comparacao-audit/${parceiroNegocioTransportadoraId}?rev=${rev}`
    );
  }
  getAuditComparacaoParceiroNegocioVendedor(parceiroNegocioVendedorId: number, rev: number) {
    return this._http.get<any[]>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios-vendedores-auditorias/comparacao-audit/${parceiroNegocioVendedorId}?rev=${rev}`
    );
  }
  getAuditComparacaoContatoCRMeParceiroNegocio(parceiroNegocioId: number, rev: number) {
    return this._http.get<any[]>(`${environment.apiUrl}/api/partner/v1/contatos-auditorias/comparacao-audit/${parceiroNegocioId}?rev=${rev}`);
  }

  retornarInsideCrm(parceiroNegocioInsideRemocaoId: number, parceiroNegocioInsideRemocaoProduto: Produto, parceiroNegocioId: number) {
    return this._http.get<ParceiroNegocio>(
      `${environment.apiUrl}/api/partner/v1/parceiro-negocios/retornar-inside-crm?parceiroNegocioInsideRemocaoId=${parceiroNegocioInsideRemocaoId}&parceiroNegocioInsideRemocaoProduto=${parceiroNegocioInsideRemocaoProduto}&parceiroNegocioId=${parceiroNegocioId}`
    );
  }

  saveParceiroTermoAnualDemurrage(parceiroNegocioId: number, termoAnualDemurrage: ParceiroTermoAnualDemurrage) {
    return this._http.post<ParceiroTermoAnualDemurrage>(
      `${environment.apiUrl}/api/partner/v1/termos-anuais-demurrages?parceiroNegocioId=${parceiroNegocioId}`,
      termoAnualDemurrage
    );
  }

  findTermoAnualDemurrageByParceiro(parceiroNegocioId: number) {
    return this._http.get<ParceiroTermoAnualDemurrage>(`${environment.apiUrl}/api/partner/v1/termos-anuais-demurrages?parceiroNegocioId=${parceiroNegocioId}`);
  }

  getPaisesAtendidos(agenteId: number) {
    return this._http.get<number[]>(`${environment.apiUrl}/api/partner/v1/parceiro-negocios/paises-atendidos/${agenteId}`);
  }

    /**
   * Busca os parceiros usando a funcao e o nome (se o nome for nulo busca apenas pela funcao)
   * @param nome
   * @param funcaoParceiro
   * @returns
   */
    getParceiroCriacaoOferta(nome = '') {
      return this._http.get<ParceiroNegocioCriacaoOfertaDTO[]>(
        `${environment.apiUrl}/api/partner/v1/parceiro-negocios/criacao-oferta?nome=${nome}`
      );
    }
}
